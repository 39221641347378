/* .watc-gra-container {
    background-color: red; */

/* } */
#watc-lst-gra-container {
    /* background-color: teal; */
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
    
}
.watc-lst-gra-container-footer  {
    /* background-color: pink; */
    margin-left: 20px;
    /* font-size: 5px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    /* align-items: space-around; */
    justify-content:space-evenly;
    margin-right: 20px;
    
    
}
#watc-lst-gra-container-footer-top {
    margin-top: 14px;
    margin-bottom: 0px;
    font-size: 9px;
}
#watc-lst-gra-container-footer-down {
    font-size: 9px;
}

