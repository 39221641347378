

  #wat-lst-modalContainer-edit {
    width: 300px;
    height: 250px;
    background-color: #1e2124;
    border: 2px solid #282d30;
    border-radius: 2px;
    
  }
  .wat-lst-edit-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wat-lst-edit-title p {
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }
  .wat-lst-edit-title button {
    margin-right: 10px;
    color: white;
    background:none;
    border: none;
  }
  .wat-lst-edit-form-body {
    display: flex;
    flex-direction: column;
  }
  .wat-lst-edit-form-body input {
    width: 200px;
    margin: auto;
    height: 24px;
    border-radius: 2px;
    border: none;
    font-size: 10px;
    padding-left: 5px;
    box-sizing: border-box;
    margin-bottom: 15px;
    outline: none;
    background-color: #282d30;
    color: white;
  }
  .wat-lst-edit-form-body input:focus {
    outline: 1px solid rgb(91, 213, 91);
    background-color: #1e2124;
    border: none;
  }
  
.wat-lst-edit-form-body input:focus:hover {
  border: none;
}
  .wat-lst-edit-form-body input:hover{
    border: 1px solid rgb(243, 242, 242);
  }
  .wat-lst-edit-form-body button {
    width: 200px;
    margin: auto;
    height: 24px;
    border-radius: 16px;
    border: none;
    background-color: rgb(96, 205, 96);
    color: white;
    font-size: 9px;
    font-weight: bold;
    cursor: pointer;
  }
  .wat-lst-edit-form-body button:hover {
    background-color:rgb(68, 163, 68) ;
  }
