

/* #modal {

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   */
  /* #wat-lst-modal-background {
  
    background-color: rgb(206, 22, 22);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  #wat-lst-modalContainer {
    width: 250px;
    min-height: 160px;
    border-radius: 10px;
    background-color: #1e2124;
    border: 2px solid #282d30;
    display: flex;
    flex-direction: column;
    /* background-color: green; */
    padding: 12px;
  }
  .add-list-header button { 
    background-color: #1e2124;
    border: none;
    color: white;
    cursor: pointer;
  }
  .add-list-header {
    display: flex;
    justify-content: space-between;
    color: white;


  }
 .add-list-mod-bod-content {
  /* background-color: rgb(128, 0, 0); */
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .add-list-mod-bod-content button {
  width: 100px;
  height: 29px;
  /* font-weight: 10px;
   */
  font-size: 10px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  padding: 5px;
 }
 .add-list-mod-rem {
  background-color:#c1121f;
  font-weight: bold;
  color: white;
  padding: 4px;
 }
 .add-list-mod-rem:hover {
  background-color:#840d15 ;
  /* padding: 3px; */
 }
 .add-list-mod-add {
  font-weight: bold;
  background-color: green;
  font-weight: bold;
  color: white;
 }
 .add-list-mod-bod-content p {
  color: white;

 }
 .add-list-mod-add:hover {
  background-color: green;
 }


  