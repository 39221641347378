.news-story{
    border: solid 2px black;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: grey;
    border-radius: 15px;
    margin: 5px;
}
.news-image{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.story-published{
    color: rgba(192, 192, 192, 0.438);
    font-size: 10px;
}
