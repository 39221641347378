



#det-wat-lst-modalContainer {
  width: 260px;
  height: 120px;
  background-color: #1e2124;
  border: 2px solid #282d30;
  border-radius: 2px;
  padding: 10px;
}


.wat-lst-del-mod-headr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.wat-lst-del-mod-headr button {
  background:none;
  border: none;
  color: white;
  cursor: pointer;
}
.wat-lst-del-mod-headr p {
  font-size: 12px;
  width: 210px;
  font-weight: bold;
}
.wat-lst-del-mod-bod p {
 font-size: 9px;
 color: white;
}
.wat-lst-del-mod-bod button {
  width: 100%;
  height: 29px;
  border-radius: 15px;
  border: none;
  font-size: 10px;
  color: white;
  background-color: #ef233c;
  font-weight: bold;
  cursor: pointer;
}