/*

#modal {

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wat-lst-modal-background {

  background-color: rgba(115, 117, 113, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
} */

#cre-wat-lst-modalContainer {
  width: 300px;
  min-height: 200px;
  border-radius: 12px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  display: flex;
  flex-direction: column;
  background-color: #1e2124;
  border: 2px solid #282d30;
  color: white;
  padding: 25px;
}
.cre-wat-mod-hed {
  display: flex;
  justify-content: space-between;
}
.cre-wat-mod-hed p {
  font-size: 12px;
}
.wat-lst-cre-mod-bod {
  display: flex;
}
.wat-lst-cre-mod-bod input {
  box-sizing: border-box;
  height: 26px;
  border-radius: 4px;
  width: 230px;
  border: none;
  border: 2px solid white;
  background-color: #f8f9fa;
  /* outline: 1px solid white; */
  margin: auto;
  transition: 0.2s;
}
.wat-lst-cre-mod-bod input::placeholder {
  padding: 4px;
  font-size: 11px;
}
.wat-lst-cre-mod-bod input:focus:hover {
  /* border: none; */
  /* outline: 1px solid #16DB65; */
  background-color: rgb(212, 211, 211);
  /* border-color:#16DB65 ; */
}

.wat-lst-cre-mod-bod input:hover {
  background-color: rgb(212, 211, 211);
  border-color: rgb(212, 211, 211);
  /* border: 2px solid  #f8f9fa; */
}
.wat-lst-cre-mod-bod input:focus {
  /* border: none; */
  outline: 1px solid #16db65;
  background-color: white;
}

.cre-wat-lst-mod-btns {
  width: 230px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: end;
  height: 30px;
  margin-top: 10px;
}
.cre-lst-mod-cancel-btn {
  background-color: white;
  margin-right: 10px;
  border-radius: 15px;
  width: 80px;
  color: #16db65;
  border: 1px solid #16db65;
  font-weight: bold;
  font-size: 12px;
}
.cre-wat-mod-hed-can {
  background-color: #1e2124;
  border: none;
  color: white;
}
.cre-lst-mod-btn {
  background-color: #16db65;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border: none;
  width: 90px;
  border-radius: 15px;
}

.name-error {
  color: darkred;
}
