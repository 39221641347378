.dash-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.5fr 2.3fr 0.7fr 0.5fr;
  gap: 0px 0px;
  overflow: hidden;
  background-color: gray;
  margin-top: 70px;
  color: white;
  background-color: black;
}
.dashboard {
  overflow-y: scroll;
  height: calc(100% - 100px);
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.dash-portfolio {
  padding-bottom: 20px;
  border-bottom: 2px solid #1e2124;
}
.dash-movers {
  margin-top: 10px;
  border-bottom: 2px solid #1e2124;
}

.dash-news {
  border-top: 2px solid #1e2124;
  margin-top: 10px;
  padding-top: 10px;
}
