.buy-modal-div {
  /* position: fixed; */
  background-color: #1e2124;
  border: 2px solid #282d30;
  color: white;
  display: flex;
  justify-content: center;
  z-index: 9999;
  border-radius: 4px;
  width: 400px;
}

.buy-modal-form-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.buy-sell-input {
  background-color: #282d30;
  border: none;
  margin-left: 5px;
  color: white;
  width: 100px;
  height: 25px;
  text-align: right;
  padding-right: 10px;
}

.buy-sell-input:focus {
  outline: none;
}

.buy-submit-button-modal {
  width: 100%;
  height: 30px;
  margin-top: 15px;
  border: 1px solid #282d30;
  background-color: #1e2124;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 200;
}

.modal-p-tag {
  margin-top: -10px;
}

.modal-p-tag-trans-good {
  margin-top: -10px;
  color: darkgreen;
}

.modal-p-tag-trans-bad {
  margin-top: -10px;
  color: darkred;
}

.validation-error {
  color: darkred;
}

.buy-submit-button-modal:hover {
  color: green;
  font-weight: 200;
  border: 1px solid darkgreen;
  cursor: pointer;
}
