/* TODO Add site wide styles */
@import url('./fonts.css');

body {
  font-family: Monsterrat-Regular;
  background-color: black;
}
#full-page{
  margin-top: 40px;
  color: white;
}
