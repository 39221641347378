.add-lst-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: teal;
}

.stockapi-container {
  margin-top: 40px;
}
