.transaction-box {
  display: flex;
  align-items: center;
  background-color: #1e2124;
  border: 2px solid #282d30;
  border-radius: 4px;
  flex-direction: column;
  width: 80%;
  margin-bottom: 10px;
}

.error-p-tag {
  color: darkred;
}

.trans-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 90%;
  padding: 10px;
  align-items: center;
}

.trans-select {
  background-color: #282d30;
  border: none;
  color: white;
  height: 25px;
  margin-top: 10px;
}

.trans-input {
  background-color: #282d30;
  border: none;
  color: white;
  text-align: right;
  height: 25px;
}

.trans-button-submit {
  height: 50px;
  width: 150px;
  margin: 5px;
  border: 1px solid #282d30;
  background-color: #1e2124;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 200;
}

.trans-button-submit:hover {
  color: green;
  font-weight: 200;
  border: 1px solid darkgreen;
  cursor: pointer;
}

.trans-button-submit-disabled {
  height: 50px;
  width: 150px;
  margin: 5px;
  border: 1px solid #282d30;
  background-color: #1e2124;
  border-radius: 4px;
  color: #282d30;
  font-size: 18px;
  font-weight: 200;
}

.owned-stocks {
  display: flex;
  background-color: #1e2124;
  border: 2px solid #282d30;
  border-radius: 4px;
  flex-direction: column;
  width: 80%;
  align-items: flex-start;
}

.owned-stocks-p {
  margin-left: 10px;
  margin-bottom: -10px;
}

.owned-stocks-ul {
  list-style-type: none;
}

.trans-error {
  color: darkred;
}
